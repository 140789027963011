import { useCallback, useState } from 'react';
import axios from 'axios';
import {useIntl} from "react-intl";
import { isEmail } from '../../../utils/utils';


export const useAxiosCheckInSystem = () => {
  const [isFound, setIsFound] = useState<null | boolean>(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [errForm, setErrForm] = useState<any>(false);
  const [requestedType, setRequestedType] = useState<null | 'email' | 'phone'>(null);
  const intl = useIntl();

  const makeCheckInSystemRequest = useCallback((submitValues: any, tab: number) => {
    const baseUrl = '/api/_p/user/find_in_system';
    let url = null;
    if (tab === 0) {
      const emailOrLogin = isEmail(submitValues.email) ? 'email' : 'login';
      url = `${baseUrl}?${emailOrLogin}=${submitValues.email}`;
      setRequestedType('email');
    } else {
      url = `${baseUrl}?phone=${submitValues.phone}`;
      setRequestedType('phone');
    }
    setLoading(true);
    axios
      .get(url)
      .then(() => setIsFound(true))
      // .catch(err => {
      //   if (err) {
      //     if (err.response.data.message === 'Пользователь не найден в системе') {
      //       setIsFound(false);
      //     }
      //     setErr(err);
      //   }
      // })
        .catch(err => {
          if (err) {
            if (err.response.status === 404 || 403) {
              setIsFound(false);
              setErrForm(true);
              setErr(intl.formatMessage({ id: 'AUTH.VALIDATION.INVALID_LOGIN' }));
            }
          }
        })
      .finally(() => {
        setLoading(false);
        setErr(null);
      });
  }, [intl]);

  return { makeCheckInSystemRequest, loading, checkInSystemErr: err, isFound, requestedType,errForm };
};
