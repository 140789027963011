import axios from 'axios';
import {
  STORES_URL,
  CREATE_STORE_URL,
  getStoreByIdUrl,
  setWorkingTimeUrl,
} from '../constants';
import { IEditWorkingTime, IStore } from '../interfaces/store';

export const createStore = (data: IStore) => axios.post(CREATE_STORE_URL, data);

export const getStores = (
  page: number,
  perPage: number,
  companyId?: number,
  userId?: number,
  company_role?: string,
  name?: string,
  address?: string,
) =>
  axios.get(STORES_URL, {
    params: { user_id: userId, company_id: companyId, page, per_page: perPage, company_role ,name,address},
  });

export const addUserStore = (id: number, add_user?: number, remove_user?: number) =>
  axios.post(`api/shop/store/${id}/users`, {}, { params: { add_user, remove_user } });

export const editStore = (id: number, data: IStore) => axios.put(getStoreByIdUrl(id), data);

export const deleteStore = (id: number) => axios.delete(getStoreByIdUrl(id));

export const getStoreById = (id: number) => axios.get(getStoreByIdUrl(id));

export const setWorkingTime = (id: number, data: IEditWorkingTime) => {
  let url = setWorkingTimeUrl(id);
  const has = Object.prototype.hasOwnProperty;

  for (const prop in data) {
    if (has.call(data, prop)) {
      url += `${url === setWorkingTimeUrl(id) ? '?' : '&'}${prop}=${(data as any)[prop]}`;
    }
  }

  return axios.post(url, {});
};

export const createInvite = (data: {
  role: string;
  email: string;
  country_code: string;
  phone_numbers: string;
  login: string;
  stores_ids?: string;
  categories_ids?: string;
  companies_ids?: string;
}) =>
  axios.post(
    'api/user/invite',
    {},
    {
      params: {
        role: data.role,
        email: data.email,
        country_code: data.country_code,
        phone_numbers: data.phone_numbers,
        login: data.login,
        stores_ids: data.stores_ids,
        categories_ids: data.categories_ids,
        companies_ids: data.companies_ids,
      },
    }
  );
