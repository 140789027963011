import { isArray } from 'lodash';
import { IStore } from '../../../interfaces/store';
import { IUser } from '../../../interfaces/user';

export const setStoreStorage = (store: IStore) => {
  localStorage.setItem('storeShift', JSON.stringify(store));
};

export const getStoreStorage = (): IStore | null => {
  const storeShift = localStorage.getItem('storeShift');
  return storeShift ? JSON.parse(storeShift) : null;
};

export const removeStoreStorage = () => {
  localStorage.removeItem('storeShift');
};

export const setAllStorage = () => {
  localStorage.setItem('allObjects', 'true');
};

export const getAllStorage = () => {
  return localStorage.getItem('allObjects');
};

export const removeAllStorage = () => {
  localStorage.removeItem('allObjects');
};

export const setStatusStorage = (status: string) => {
  localStorage.setItem('statusShift', status);
};

export const getStatusStorage = () => {
  const storeShift = localStorage.getItem('statusShift');
  return storeShift;
};

export const removeStatusStorage = () => {
  localStorage.removeItem('statusShift');
};

export const setCancelStorage = (status: boolean) => {
  localStorage.setItem('cancelShift', JSON.stringify(status));
};

export const getCancelStorage = () => {
  const storeShift = localStorage.getItem('cancelShift');
  return storeShift ? JSON.parse(storeShift) : null;
};

export const removeCancelStorage = () => {
  localStorage.removeItem('cancelShift');
};

export const setManagerStorage = (user: IUser[]) => {
  localStorage.setItem('managerShift', JSON.stringify(user));
};

export const getManagerStorage = () => {
  const managerShift = localStorage.getItem('managerShift');
  if (managerShift && managerShift !== 'undefined') {
    const parseData = JSON.parse(managerShift);
    // eslint-disable-next-line no-nested-ternary
    return !isArray(parseData) ? [parseData] : isArray(parseData) ? parseData : [];
  }
  return [];
};

export const removeManagerStorage = () => {
  localStorage.removeItem('managerShift');
};

export const setMonthStorage = (status: string) => {
  localStorage.setItem('monthShift', status);
};

export const getMonthStorage = () => {
  return localStorage.getItem('monthShift');
};

export const removeMonthStorage = () => {
  localStorage.removeItem('monthShift');
};

export const setSortStorage = (status: string) => {
  localStorage.setItem('sortShift', status);
};

export const getSortStorage = () => {
  const sortShift = localStorage.getItem('sortShift');
  return sortShift;
};

export const removeSortStorage = () => {
  localStorage.removeItem('sortShift');
};
